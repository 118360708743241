import React from "react";
import {injectIntl} from "gatsby-plugin-react-intl";

class BlogTagsSelector extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            tags: [
                {value: 'all', label: this.props.intl.formatMessage({id: "blogTags.all"})},
                {value: 'news', label: this.props.intl.formatMessage({id: "blogTags.news"})},
                {value: 'best-practices', label: this.props.intl.formatMessage({id: "blogTags.bestpractices"})},
                {value: 'product', label: this.props.intl.formatMessage({id: "blogTags.product"})},
                {value: 'marketing', label: this.props.intl.formatMessage({id: "blogTags.marketing"})},
                {value: 'engineering', label: this.props.intl.formatMessage({id: "blogTags.engineering"})},
                {value: 'staff', label: this.props.intl.formatMessage({id: "blogTags.staff"})}
            ]
        }
    }

    handleJumpToPage(e){
        e.persist();
        if (e && e.target && e.target.value) {

            let newLocation = '/blog/';
            if (e.target.value !== 'all') {
                newLocation = '/blog/tags/' + e.target.value + '/';
            }

            if (newLocation !== this.props.location.pathname) {
                window.location.href = newLocation;
            }
        }
    }

    createOptions(){
        let items = this.state.tags.map((nav, i) => {
            return <option key={i} value={nav.value}>{nav.label}</option>
        });

        return items;
    }

    fetchSelectedValue(value){

        let selected = 'all',
          components = value.split('/');

        this.state.tags.forEach((t) => {
            if (components.includes(t.value)) {
                selected =  t.value;
            }
        });
        return selected;
    }

    render() {
        return (
          <div className={`blog-tags-selector`}>
              <div className={`blog-tags`}>
                  <select defaultValue={this.fetchSelectedValue(this.props.location.pathname)} onChange={this.handleJumpToPage.bind(this)} onBlur={this.handleJumpToPage.bind(this)}>
                      {this.createOptions()}
                  </select>
              </div>
          </div>
        )
    }
}

export default injectIntl(BlogTagsSelector);
